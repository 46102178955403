import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

import { Plus_Jakarta_Sans } from '@next/font/google'
import localFont from '@next/font/local'

const plusJakartaSans = Plus_Jakarta_Sans({
  subsets: ['latin'],
  variable: '--font-base',
  display: 'swap',
})

const drukWide = localFont({
  src: './Druk_Wide_Bold.ttf',
  variable: '--font-title',
  display: 'swap',
})

export const DRUK_WIDE_VARIABLE = drukWide.variable
export const PLUS_JAKARTA_SANS_VARIABLE = plusJakartaSans.variable

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Navbar
        className={`${plusJakartaSans.variable} ${drukWide.variable} font-base`}
      />
      <main
        className={`bg-black ${plusJakartaSans.variable} ${drukWide.variable} font-base`}
      >
        <Component {...pageProps} />
      </main>
      <Footer
        className={`${plusJakartaSans.variable} ${drukWide.variable} font-base`}
      />
    </>
  )
}
