import clsx from 'clsx'
import { useState } from 'react'
import {
  DiscordIcon,
  GitHubIcon,
  LinkedInIcon,
  TwitterIcon,
  XIcon
} from './Icons'
import { Roadmap } from './Roadmap'

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid'

const navigation = {
  legal: [
    {
      name: 'Terms of Use',
      href: 'https://docs.capsulenft.com/additional-resources/legal/terms-of-use',
    },
    {
      name: 'Privacy Policy',
      href: 'https://docs.capsulenft.com/additional-resources/legal/privacy-policy',
    },
    {
      name: 'Cookie Policy',
      href: 'https://docs.capsulenft.com/additional-resources/legal/cookie-policy',
    },
    {
      name: 'Risks',
      href: 'https://docs.capsulenft.com/additional-resources/legal/risks',
    },
  ],
  company: [
    {
      link: () => (
        <a
          href={'https://app.capsulenft.com/'}
          className="text-zinc-300 hover:text-white"
          rel="noopener noreferrer"
          target="_blank"
        >
          App
        </a>
      ),
    },
    { link: () => <Roadmap className="text-zinc-300 hover:text-white" /> },
    {
      link: () => (
        <a
          href={'https://docs.capsulenft.com/'}
          className="text-zinc-300 hover:text-white"
          rel="noopener noreferrer"
          target="_blank"
        >
          Docs
        </a>
      ),
    },
  ],
  social: [
    {
      name: 'Twitter/X',
      href: 'https://x.com/capsule_xyz',
      icon: (props: Record<string, any>) => <XIcon {...props} />,
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/capsule-nft',
      icon: (props: Record<string, any>) => <LinkedInIcon {...props} />,
    },
    {
      name: 'Discord',
      href: 'https://discord.gg/CapsuleNFT',
      icon: (props: Record<string, any>) => <DiscordIcon {...props} />,
    },
    {
      name: 'GitHub',
      href: 'https://github.com/CapsuleNFT',
      icon: (props: Record<string, any>) => <GitHubIcon {...props} />,
    },
  ],
}

async function onNewsLetterSubmitHandler(
  e: React.FormEvent<HTMLFormElement>,
  cb: (err: any) => void
) {
  e.preventDefault()

  const target = e.target as typeof e.target & {
    email: { value: string }
  }

  const email = target.email.value

  if (email) {
    try {
      const response = await fetch('/api/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })

      if (!response.ok) {
        const data = await response.json()
        cb(new Error(data.message))
      } else {
        cb(null)
      }
    } catch (err) {
      // Log to Sentry, TrackJS, New Relic, etc
      cb(new Error('Something went wrong. Please try again later.'))
    }
  }
}

type NewsletterApiResponse = {
  status: 'success' | 'error' | null
  message?: string
}

function NewsletterForm() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [status, setStatus] = useState<NewsletterApiResponse>({
    status: null,
    message: '',
  })

  const cb = (err: any) => {
    setIsSubmitting(false)
    if (err) {
      setStatus({ status: 'error', message: err.message })
    } else {
      setStatus({ status: 'success' })
    }
  }

  return (
    <div className="mt-8 ml-auto xl:mt-0">
      <h3 className="font-medium text-white">Subscribe to our newsletter</h3>
      <p className="mt-4 text-zinc-300">
        The latest news, articles, and resources, sent to your inbox weekly.
      </p>
      <form
        className="mt-4 sm:flex sm:max-w-md"
        onSubmit={(e) => {
          setIsSubmitting(true)
          onNewsLetterSubmitHandler(e, cb)
        }}
        onChange={() => setStatus({ status: null, message: '' })}
      >
        <label htmlFor="email-address" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          name="email"
          id="email"
          autoComplete="email"
          required
          className="w-full min-w-0 appearance-none rounded-md border border-transparent bg-white py-2 px-4 text-base text-zinc-900 placeholder-gray-500 focus:border-white focus:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          placeholder="Enter your email"
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            type="submit"
            className="bg-capsule-gradient flex w-full items-center justify-center rounded-md py-2 px-4 text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <svg
                className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="white"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="white"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            Subscribe
          </button>
        </div>
      </form>
      {status.status === 'success' && (
        <p className="mt-4 flex items-center text-green-400">
          <CheckCircleIcon className="mr-2 h-4 w-4" /> Success! Thank you for
          subscribing!
        </p>
      )}
      {status.status === 'error' && (
        <p className="mt-4 flex items-center text-red-400">
          <ExclamationCircleIcon className="mr-2 h-4 w-4" /> {status.message}
        </p>
      )}
      {status.status === null && (
        <p className="mt-4 text-sm text-zinc-300">
          Learn more about our{' '}
          <a
            className="text-capsule-purple hover:underline"
            href="https://docs.capsulenft.com/additional-resources/legal/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
          .
        </p>
      )}
    </div>
  )
}

export default function Footer({ className }: { className?: string }) {
  return (
    <footer
      className={clsx(className, 'bg-capsule-dark')}
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-2 xl:place-content-between xl:gap-8">
          <div className="grid grid-cols-2 gap-8 sm:grid-cols-3">
            <div className="mt-12 md:mt-0">
              <h3 className="text-base font-medium text-white">Legal</h3>
              <ul role="list" className="mt-4 space-y-4">
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className="text-base text-zinc-300 hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h3 className="text-base font-medium text-white">Company</h3>
              <ul role="list" className="mt-4 space-y-4">
                {navigation.company.map((item, index) => (
                  <li key={index}>
                    <item.link />
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h3 className="text-base font-medium text-white">Social</h3>
              <ul role="list" className="mt-4 space-y-4">
                {navigation.social.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className="text-base text-zinc-300 hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <NewsletterForm />
        </div>
        <div className="mt-8 border-t border-zinc-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                rel="noopener noreferrer"
                target="_blank"
                className="text-zinc-400 hover:text-zinc-300"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className={`${item.name == "Twitter/X" ? "h-6 w-4" : "h-6 w-6"}`} aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-zinc-400 md:order-1 md:mt-0">
            &copy; {new Date().getFullYear()} CapsuleNFT. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
