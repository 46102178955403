import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export function Button({
  as: Component = 'button',
  children,
  className,
  ...props
}: PropsWithChildren<{
  as?: React.ElementType
  href?: string
  className?: string
  [x: string]: any
}>) {
  className = clsx('cursor-pointer rounded-xl text-white', className)

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  )
}
