import { PropsWithChildren, useRef } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

export function Box({
  as: Component = 'div',
  className,
  children,
  ...props
}: PropsWithChildren<{
  as?: React.ElementType
  className?: string
  [x: string]: any
}>) {
  return (
    <Component
      className={clsx('group relative flex flex-col rounded-lg', className)}
      {...props}
    >
      {children}
    </Component>
  )
}

Box.Title = function CardTitle({
  as: Component = 'h3',
  children,
  className,
}: PropsWithChildren<{ as?: React.ElementType; className?: string }>) {
  return (
    <Component
      className={clsx(
        'relative z-10 font-title tracking-tight text-white',
        className
      )}
    >
      {children}
    </Component>
  )
}

Box.Subtitle = function CardSubtitle({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <p
      className={clsx(
        'relative z-10 text-base font-medium text-white',
        className
      )}
    >
      {children}
    </p>
  )
}

Box.Image = function CardImage({
  src,
  className,
  ...props
}: {
  src: any
  className?: string
  [x: string]: any
}) {
  return (
    <div className="relative">
      <Image
        className={clsx('z-10', className)}
        src={src}
        alt={''}
        placeholder="blur"
        {...props}
      />
    </div>
  )
}

Box.Video = function CardVideo({
  src,
  className,
  play,
  ...props
}: {
  src: any
  className?: string
  play?: boolean
  [x: string]: any
}) {
  const videoRef = useRef<HTMLVideoElement>(null)

  if (videoRef.current) {
    if (play) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }

  return (
    <div>
      <video ref={videoRef} className={clsx('z-10', className)} {...props}>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  )
}

Box.Description = function CardDescription({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <p className={clsx('relative z-10 mt-2 text-base text-white', className)}>
      {children}
    </p>
  )
}

Box.Link = function CardLink({
  children,
  ...props
}: PropsWithChildren<{ href: string }>) {
  return (
    <a {...props}>
      <span className="absolute -inset-y-6 -inset-x-4 z-20 sm:-inset-x-6 sm:rounded-2xl" />
      <span className="relative z-10">{children}</span>
    </a>
  )
}

Box.Cta = function CardCta({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      aria-hidden="true"
      className={clsx(
        'relative z-10 flex items-center text-sm font-bold',
        className
      )}
    >
      {children}
    </div>
  )
}

Box.Bezel = function CardBezel({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      aria-hidden="true"
      className={clsx('m-1 rounded-lg border border-zinc-500', className)}
    >
      {children}
    </div>
  )
}

Box.BackgroundImage = function CardBackgroundImage({
  src,
  className,
  ...props
}: PropsWithChildren<{ src: any; className?: string; [x: string]: any }>) {
  return (
    <Image
      className={clsx(
        'absolute inset-0 h-full w-full rounded-2xl object-cover object-center',
        className
      )}
      src={src}
      alt={''}
      placeholder="blur"
      {...props}
    />
  )
}
