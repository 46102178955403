import Link from 'next/link'
import Image from 'next/image'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  QuestionMarkCircleIcon,
  BookOpenIcon,
  XMarkIcon,
  HomeIcon,
  MapIcon,
} from '@heroicons/react/20/solid'

import logoCapsule from '@/images/brand/logo.png'
import { Roadmap } from './Roadmap'
import { Button } from './Button'
import clsx from 'clsx'
import { DiscordIcon, LinkedInIcon, TwitterIcon } from './Icons'

const links = [
  {
    name: 'Home',
    component: Link,
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'Docs',
    component: ExternalLink,
    href: 'https://docs.capsulenft.com/',
    icon: BookOpenIcon,
  },
]

function ExternalLink({ href, className, children }: any) {
  return (
    <a
      href={href}
      className={className}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  )
}

export default function Navbar({ className }: { className?: string }) {
  return (
    <Popover
      className={clsx(
        className,
        'relative sticky top-0 z-[20000] bg-capsule-dark'
      )}
    >
      <div className="container mx-auto px-4 lg:px-8">
        <div className="flex items-center justify-between py-8 md:justify-between md:space-x-10 md:py-6">
          <Link href="/">
            <span className="sr-only">Capsule</span>
            <Image src={logoCapsule} alt="Capsule" className="w-32" />
          </Link>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-capsule-dark p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden items-center space-x-10 md:flex"
          >
            <Link href="/" className="text-zinc-300 hover:text-white">
              Home
            </Link>
            <Button
              as="a"
              href="https://docs.capsulenft.com/"
              className="text-zinc-300 hover:text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              Docs
            </Button>
            <Roadmap className="bg-transparent text-zinc-300 hover:text-white" />
            <Button
              as="a"
              href="https://app.capsulenft.com/"
              className="bg-capsule-gradient flex w-full items-center justify-center rounded-md px-4 py-3 text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              Launch App
            </Button>
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
        >
          <div className="divide-y-2 divide-zinc-500 rounded-lg bg-capsule-dark shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-4 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <Link href="/">
                  <span className="sr-only">Capsule</span>
                  <Image src={logoCapsule} alt="Capsule" className="w-32" />
                </Link>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-capsule-dark p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {links.map((item) => (
                    <Popover.Button
                      key={item.name}
                      as={item.component}
                      href={item.href}
                      className="-m-3 flex items-center rounded-md p-3"
                    >
                      <item.icon
                        className="h-6 w-6 flex-shrink-0 fill-white"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-white">
                        {item.name}
                      </span>
                    </Popover.Button>
                  ))}
                </nav>
              </div>
            </div>
            <div className="space-y-6 py-6 px-4">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Button
                  as="a"
                  href="https://x.com/capsule_xyz"
                  className="flex items-center text-base font-medium text-white"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <TwitterIcon className="h-6 w-6 fill-zinc-600" />
                  <span className="ml-3 text-base font-medium">Twitter/X</span>
                </Button>
                <Button
                  as="a"
                  href="https://discord.gg/8Z8Y4Z8"
                  className="flex text-base font-medium text-white"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <DiscordIcon className="h-6 w-6 fill-zinc-600" />
                  <span className="ml-3 text-base font-medium">Discord</span>
                </Button>
                <Button
                  as="a"
                  href="https://www.linkedin.com/company/capsule-nft"
                  className="flex text-base font-medium text-white"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <LinkedInIcon className="h-6 w-6 fill-zinc-600" />
                  <span className="ml-3 text-base font-medium">LinkedIn</span>
                </Button>
              </div>
            </div>
            <div className="space-y-6 py-6 px-4">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a className="text-base font-medium text-zinc-500" href="mailto:business@capsulenft.com">
                  Contact
                </a>
                <a
                  href="https://docs.capsulenft.com/additional-resources/legal"
                  className="text-base font-medium text-zinc-500"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Legal
                </a>
              </div>
              <Popover.Button
                as={Button}
                className="bg-capsule-gradient w-full py-2 px-4 font-medium text-white"
                onClick={() => {
                  // open app in new tab
                  window.open('https://app.capsulenft.com/', '_blank')
                }}
              >
                Launch App
              </Popover.Button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
