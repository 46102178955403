import Image from 'next/image'

type IconProps = {
  [x: string]: any
}

export function ForwardSlashIcon(props: IconProps) {
  return (
    <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
    </svg>
  )
}

export function DiscordIcon(props: IconProps) {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418Z" />
    </svg>
  )
}

export function InstagramIcon(props: IconProps) {
  return (
    <svg width="1.25rem" height="1.25rem" viewBox="0 0 16 16" {...props}>
      <path
        fill="#fff"
        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 11.5c0 .8-.7 1.5-1.5 1.5h-5c-.8 0-1.5-.7-1.5-1.5v-5c0-.8.7-1.5 1.5-1.5h5c.8 0 1.5.7 1.5 1.5v5zm-6.3-3.8c0-1.3 1.1-2.3 2.3-2.3s2.3 1.1 2.3 2.3-1.1 2.3-2.3 2.3-2.3-1-2.3-2.3zm6.3 3.8c0 .3-.2.5-.5.5h-1c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5h1c.3 0 .5.2.5.5v1z"
      />
    </svg>
  )
}

export function LinkedInIcon(props: IconProps) {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 01-1.548-1.549 1.548 1.548 0 111.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z" />
    </svg>
  )
}

export function TwitterIcon(props: IconProps) {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path d="M20.055 7.983c.011.174.011.347.011.523 0 5.338-3.92 11.494-11.09 11.494v-.003A10.755 10.755 0 0 1 3 18.186c.308.038.618.057.928.058a7.655 7.655 0 0 0 4.841-1.733c-1.668-.032-3.13-1.16-3.642-2.805a3.753 3.753 0 0 0 1.76-.07C5.07 13.256 3.76 11.6 3.76 9.676v-.05a3.77 3.77 0 0 0 1.77.505C3.816 8.945 3.288 6.583 4.322 4.737c1.98 2.524 4.9 4.058 8.034 4.22a4.137 4.137 0 0 1 1.128-3.86A3.807 3.807 0 0 1 19 5.274a7.657 7.657 0 0 0 2.475-.98c-.29.934-.9 1.729-1.713 2.233A7.54 7.54 0 0 0 22 5.89a8.084 8.084 0 0 1-1.945 2.093Z" />
    </svg>
  )
}

export function XIcon(props: IconProps) {
  return (
    <svg fill="currentColor" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"/>
    </svg>
  )
}

/*
export function YoutubeIcon(props: IconProps) {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5l-6-3.5v7z" />
    </svg>
  )
}
*/

export function BagIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 18 18" {...props}>
      <path
        d="M6.60746 1.5L3.89246 4.2225"
        stroke="#E7E7E1"
        stroke-width="1.125"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3925 1.5L14.1075 4.2225"
        stroke="#E7E7E1"
        stroke-width="1.125"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.5 5.8877C1.5 4.5002 2.2425 4.3877 3.165 4.3877H14.835C15.7575 4.3877 16.5 4.5002 16.5 5.8877C16.5 7.5002 15.7575 7.3877 14.835 7.3877H3.165C2.2425 7.3877 1.5 7.5002 1.5 5.8877Z"
        stroke="#E7E7E1"
        stroke-width="1.125"
      />
      <path
        d="M7.32007 10.5V13.1625"
        stroke="#E7E7E1"
        stroke-width="1.125"
        stroke-linecap="round"
      />
      <path
        d="M10.77 10.5V13.1625"
        stroke="#E7E7E1"
        stroke-width="1.125"
        stroke-linecap="round"
      />
      <path
        d="M2.625 7.5L3.6825 13.98C3.9225 15.435 4.5 16.5 6.645 16.5H11.1675C13.5 16.5 13.845 15.48 14.115 14.07L15.375 7.5"
        stroke="#E7E7E1"
        stroke-width="1.125"
        stroke-linecap="round"
      />
    </svg>
  )
}

export function CopiedIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <rect
        width="48"
        height="48"
        rx="24"
        fill="url(#paint0_linear_1904_6267)"
      />
      <path
        d="M29.3333 25.2001V30.8001C29.3333 35.4667 27.4667 37.3334 22.8 37.3334H17.2C12.5333 37.3334 10.6667 35.4667 10.6667 30.8001V25.2001C10.6667 20.5334 12.5333 18.6667 17.2 18.6667H22.8C27.4667 18.6667 29.3333 20.5334 29.3333 25.2001Z"
        fill="white"
      />
      <path
        d="M30.8 10.6667H25.2C20.9612 10.6667 19.0433 12.2178 18.7265 15.9876C18.6799 16.5426 19.135 17.0001 19.6919 17.0001H22.8C28.4 17.0001 31 19.6001 31 25.2001V28.3082C31 28.8651 31.4575 29.3202 32.0124 29.2735C35.7823 28.9568 37.3333 27.0389 37.3333 22.8001V17.2001C37.3333 12.5334 35.4667 10.6667 30.8 10.6667Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1904_6589"
          x1="9.375"
          y1="-4.98176e-07"
          x2="51.1222"
          y2="27.6839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export function GamingCapsuleIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <rect
        width="48"
        height="48"
        rx="24"
        fill="url(#paint0_linear_1904_6356)"
      />
      <g clipPath="url(#clip0_1904_6356)">
        <path
          d="M15.6642 16.6499H15.6583C15.1106 16.6499 14.6667 16.206 14.6667 15.6583C14.6667 15.1106 15.1106 14.6667 15.6583 14.6667H15.6642C16.2119 14.6667 16.6558 15.1106 16.6558 15.6583C16.6558 16.206 16.2119 16.6499 15.6642 16.6499Z"
          fill="white"
        />
        <path
          d="M19.0001 16.6499H18.9941C18.4464 16.6499 18.0025 16.206 18.0025 15.6583C18.0025 15.1106 18.4464 14.6667 18.9941 14.6667H19.0001C19.5477 14.6667 19.9916 15.1106 19.9916 15.6583C19.9916 16.206 19.5477 16.6499 19.0001 16.6499Z"
          fill="white"
        />
        <path
          d="M22.3353 16.6499H22.3294C21.7817 16.6499 21.3378 16.206 21.3378 15.6583C21.3378 15.1106 21.7817 14.6667 22.3294 14.6667H22.3353C22.883 14.6667 23.3269 15.1106 23.3269 15.6583C23.3269 16.206 22.883 16.6499 22.3353 16.6499Z"
          fill="white"
        />
        <path
          d="M15.6642 19.9973H15.6583C15.1106 19.9973 14.6667 19.5534 14.6667 19.0057C14.6667 18.4581 15.1106 18.0142 15.6583 18.0142H15.6642C16.2119 18.0142 16.6558 18.4581 16.6558 19.0057C16.6558 19.5534 16.2119 19.9973 15.6642 19.9973Z"
          fill="white"
        />
        <path
          d="M19.0001 19.9973H18.9941C18.4464 19.9973 18.0025 19.5534 18.0025 19.0057C18.0025 18.4581 18.4464 18.0142 18.9941 18.0142H19.0001C19.5477 18.0142 19.9916 18.4581 19.9916 19.0057C19.9916 19.5534 19.5477 19.9973 19.0001 19.9973Z"
          fill="white"
        />
        <path
          d="M22.3353 19.9973H22.3294C21.7817 19.9973 21.3378 19.5534 21.3378 19.0057C21.3378 18.4581 21.7817 18.0142 22.3294 18.0142H22.3353C22.883 18.0142 23.3269 18.4581 23.3269 19.0057C23.3269 19.5534 22.883 19.9973 22.3353 19.9973Z"
          fill="white"
        />
        <path
          d="M15.6642 23.3443H15.6583C15.1106 23.3443 14.6667 22.9004 14.6667 22.3527C14.6667 21.805 15.1106 21.3611 15.6583 21.3611H15.6642C16.2119 21.3611 16.6558 21.805 16.6558 22.3527C16.6558 22.9004 16.2119 23.3443 15.6642 23.3443Z"
          fill="white"
        />
        <path
          d="M19.0001 23.3443H18.9941C18.4464 23.3443 18.0025 22.9004 18.0025 22.3527C18.0025 21.805 18.4464 21.3611 18.9941 21.3611H19.0001C19.5477 21.3611 19.9916 21.805 19.9916 22.3527C19.9916 22.9004 19.5477 23.3443 19.0001 23.3443Z"
          fill="white"
        />
        <path
          d="M22.3353 23.3443H22.3294C21.7817 23.3443 21.3378 22.9004 21.3378 22.3527C21.3378 21.805 21.7817 21.3611 22.3294 21.3611H22.3353C22.883 21.3611 23.3269 21.805 23.3269 22.3527C23.3269 22.9004 22.883 23.3443 22.3353 23.3443Z"
          fill="white"
        />
        <path
          d="M25.6706 16.6499H25.6646C25.117 16.6499 24.6731 16.206 24.6731 15.6583C24.6731 15.1106 25.117 14.6667 25.6646 14.6667H25.6706C26.2183 14.6667 26.6622 15.1106 26.6622 15.6583C26.6622 16.206 26.2183 16.6499 25.6706 16.6499Z"
          fill="white"
        />
        <path
          d="M29.0064 16.6499H29.0005C28.4528 16.6499 28.0089 16.206 28.0089 15.6583C28.0089 15.1106 28.4528 14.6667 29.0005 14.6667H29.0064C29.5541 14.6667 29.998 15.1106 29.998 15.6583C29.998 16.206 29.5541 16.6499 29.0064 16.6499Z"
          fill="white"
        />
        <path
          d="M32.3417 16.6499H32.3358C31.7881 16.6499 31.3442 16.206 31.3442 15.6583C31.3442 15.1106 31.7881 14.6667 32.3358 14.6667H32.3417C32.8894 14.6667 33.3333 15.1106 33.3333 15.6583C33.3333 16.206 32.8894 16.6499 32.3417 16.6499Z"
          fill="white"
        />
        <path
          d="M25.6706 19.9973H25.6646C25.117 19.9973 24.6731 19.5534 24.6731 19.0057C24.6731 18.4581 25.117 18.0142 25.6646 18.0142H25.6706C26.2183 18.0142 26.6622 18.4581 26.6622 19.0057C26.6622 19.5534 26.2183 19.9973 25.6706 19.9973Z"
          fill="white"
        />
        <path
          d="M29.0064 19.9973H29.0005C28.4528 19.9973 28.0089 19.5534 28.0089 19.0057C28.0089 18.4581 28.4528 18.0142 29.0005 18.0142H29.0064C29.5541 18.0142 29.998 18.4581 29.998 19.0057C29.998 19.5534 29.5541 19.9973 29.0064 19.9973Z"
          fill="white"
        />
        <path
          d="M32.3417 19.9973H32.3358C31.7881 19.9973 31.3442 19.5534 31.3442 19.0057C31.3442 18.4581 31.7881 18.0142 32.3358 18.0142H32.3417C32.8894 18.0142 33.3333 18.4581 33.3333 19.0057C33.3333 19.5534 32.8894 19.9973 32.3417 19.9973Z"
          fill="white"
        />
        <path
          d="M25.6706 23.3443H25.6646C25.117 23.3443 24.6731 22.9004 24.6731 22.3527C24.6731 21.805 25.117 21.3611 25.6646 21.3611H25.6706C26.2183 21.3611 26.6622 21.805 26.6622 22.3527C26.6622 22.9004 26.2183 23.3443 25.6706 23.3443Z"
          fill="white"
        />
        <path
          d="M29.0064 23.3443H29.0005C28.4528 23.3443 28.0089 22.9004 28.0089 22.3527C28.0089 21.805 28.4528 21.3611 29.0005 21.3611H29.0064C29.5541 21.3611 29.998 21.805 29.998 22.3527C29.998 22.9004 29.5541 23.3443 29.0064 23.3443Z"
          fill="white"
        />
        <path
          d="M32.3417 23.3443H32.3358C31.7881 23.3443 31.3442 22.9004 31.3442 22.3527C31.3442 21.805 31.7881 21.3611 32.3358 21.3611H32.3417C32.8894 21.3611 33.3333 21.805 33.3333 22.3527C33.3333 22.9004 32.8894 23.3443 32.3417 23.3443Z"
          fill="white"
        />
        <path
          d="M15.6642 26.6922H15.6583C15.1106 26.6922 14.6667 26.2483 14.6667 25.7006C14.6667 25.1529 15.1106 24.709 15.6583 24.709H15.6642C16.2119 24.709 16.6558 25.1529 16.6558 25.7006C16.6558 26.2483 16.2119 26.6922 15.6642 26.6922Z"
          fill="white"
        />
        <path
          d="M19.0001 26.6922H18.9941C18.4464 26.6922 18.0025 26.2483 18.0025 25.7006C18.0025 25.1529 18.4464 24.709 18.9941 24.709H19.0001C19.5477 24.709 19.9916 25.1529 19.9916 25.7006C19.9916 26.2483 19.5477 26.6922 19.0001 26.6922Z"
          fill="white"
        />
        <path
          d="M22.3353 26.6922H22.3294C21.7817 26.6922 21.3378 26.2483 21.3378 25.7006C21.3378 25.1529 21.7817 24.709 22.3294 24.709H22.3353C22.883 24.709 23.3269 25.1529 23.3269 25.7006C23.3269 26.2483 22.883 26.6922 22.3353 26.6922Z"
          fill="white"
        />
        <path
          d="M15.6642 30.0403H15.6583C15.1106 30.0403 14.6667 29.5964 14.6667 29.0487C14.6667 28.501 15.1106 28.0571 15.6583 28.0571H15.6642C16.2119 28.0571 16.6558 28.501 16.6558 29.0487C16.6558 29.5964 16.2119 30.0403 15.6642 30.0403Z"
          fill="white"
        />
        <path
          d="M19.0001 30.0403H18.9941C18.4464 30.0403 18.0025 29.5964 18.0025 29.0487C18.0025 28.501 18.4464 28.0571 18.9941 28.0571H19.0001C19.5477 28.0571 19.9916 28.501 19.9916 29.0487C19.9916 29.5964 19.5477 30.0403 19.0001 30.0403Z"
          fill="white"
        />
        <path
          d="M22.3353 30.0403H22.3294C21.7817 30.0403 21.3378 29.5964 21.3378 29.0487C21.3378 28.501 21.7817 28.0571 22.3294 28.0571H22.3353C22.883 28.0571 23.3269 28.501 23.3269 29.0487C23.3269 29.5964 22.883 30.0403 22.3353 30.0403Z"
          fill="white"
        />
        <path
          d="M15.6642 33.3877H15.6583C15.1106 33.3877 14.6667 32.9438 14.6667 32.3961C14.6667 31.8484 15.1106 31.4045 15.6583 31.4045H15.6642C16.2119 31.4045 16.6558 31.8484 16.6558 32.3961C16.6558 32.9438 16.2119 33.3877 15.6642 33.3877Z"
          fill="white"
        />
        <path
          d="M19.0001 33.3877H18.9941C18.4464 33.3877 18.0025 32.9438 18.0025 32.3961C18.0025 31.8484 18.4464 31.4045 18.9941 31.4045H19.0001C19.5477 31.4045 19.9916 31.8484 19.9916 32.3961C19.9916 32.9438 19.5477 33.3877 19.0001 33.3877Z"
          fill="white"
        />
        <path
          d="M22.3353 33.3877H22.3294C21.7817 33.3877 21.3378 32.9438 21.3378 32.3961C21.3378 31.8484 21.7817 31.4045 22.3294 31.4045H22.3353C22.883 31.4045 23.3269 31.8484 23.3269 32.3961C23.3269 32.9438 22.883 33.3877 22.3353 33.3877Z"
          fill="white"
        />
        <path
          d="M25.6706 26.6922H25.6646C25.117 26.6922 24.6731 26.2483 24.6731 25.7006C24.6731 25.1529 25.117 24.709 25.6646 24.709H25.6706C26.2183 24.709 26.6622 25.1529 26.6622 25.7006C26.6622 26.2483 26.2183 26.6922 25.6706 26.6922Z"
          fill="white"
        />
        <path
          d="M29.0064 26.6922H29.0005C28.4528 26.6922 28.0089 26.2483 28.0089 25.7006C28.0089 25.1529 28.4528 24.709 29.0005 24.709H29.0064C29.5541 24.709 29.998 25.1529 29.998 25.7006C29.998 26.2483 29.5541 26.6922 29.0064 26.6922Z"
          fill="white"
        />
        <path
          d="M32.3417 26.6922H32.3358C31.7881 26.6922 31.3442 26.2483 31.3442 25.7006C31.3442 25.1529 31.7881 24.709 32.3358 24.709H32.3417C32.8894 24.709 33.3333 25.1529 33.3333 25.7006C33.3333 26.2483 32.8894 26.6922 32.3417 26.6922Z"
          fill="white"
        />
        <path
          d="M25.6706 30.0403H25.6646C25.117 30.0403 24.6731 29.5964 24.6731 29.0487C24.6731 28.501 25.117 28.0571 25.6646 28.0571H25.6706C26.2183 28.0571 26.6622 28.501 26.6622 29.0487C26.6622 29.5964 26.2183 30.0403 25.6706 30.0403Z"
          fill="white"
        />
        <path
          d="M29.0064 30.0403H29.0005C28.4528 30.0403 28.0089 29.5964 28.0089 29.0487C28.0089 28.501 28.4528 28.0571 29.0005 28.0571H29.0064C29.5541 28.0571 29.998 28.501 29.998 29.0487C29.998 29.5964 29.5541 30.0403 29.0064 30.0403Z"
          fill="white"
        />
        <path
          d="M32.3417 30.0403H32.3358C31.7881 30.0403 31.3442 29.5964 31.3442 29.0487C31.3442 28.501 31.7881 28.0571 32.3358 28.0571H32.3417C32.8894 28.0571 33.3333 28.501 33.3333 29.0487C33.3333 29.5964 32.8894 30.0403 32.3417 30.0403Z"
          fill="white"
        />
        <path
          d="M25.6706 33.3877H25.6646C25.117 33.3877 24.6731 32.9438 24.6731 32.3961C24.6731 31.8484 25.117 31.4045 25.6646 31.4045H25.6706C26.2183 31.4045 26.6622 31.8484 26.6622 32.3961C26.6622 32.9438 26.2183 33.3877 25.6706 33.3877Z"
          fill="white"
        />
        <path
          d="M29.0064 33.3877H29.0005C28.4528 33.3877 28.0089 32.9438 28.0089 32.3961C28.0089 31.8484 28.4528 31.4045 29.0005 31.4045H29.0064C29.5541 31.4045 29.998 31.8484 29.998 32.3961C29.998 32.9438 29.5541 33.3877 29.0064 33.3877Z"
          fill="white"
        />
        <path
          d="M32.3417 33.3877H32.3358C31.7881 33.3877 31.3442 32.9438 31.3442 32.3961C31.3442 31.8484 31.7881 31.4045 32.3358 31.4045H32.3417C32.8894 31.4045 33.3333 31.8484 33.3333 32.3961C33.3333 32.9438 32.8894 33.3877 32.3417 33.3877Z"
          fill="white"
        />
        <path
          d="M24 40C19.726 40 15.7085 38.336 12.6865 35.3135C9.664 32.2915 8 28.274 8 24C8 19.726 9.664 15.7085 12.6865 12.6865C15.7085 9.664 19.726 8 24 8C28.274 8 32.2915 9.664 35.3135 12.6865C38.336 15.7085 40 19.726 40 24C40 28.274 38.336 32.2915 35.3135 35.3135C32.2915 38.336 28.274 40 24 40ZM24 8.58182C19.8819 8.58182 16.0099 10.1853 13.0979 13.0979C10.1853 16.0099 8.58182 19.8819 8.58182 24C8.58182 28.1181 10.1853 31.9901 13.0979 34.9021C16.0099 37.8147 19.8819 39.4182 24 39.4182C28.1181 39.4182 31.9901 37.8147 34.9021 34.9021C37.8147 31.9901 39.4182 28.1181 39.4182 24C39.4182 19.8819 37.8147 16.0099 34.9021 13.0979C31.9901 10.1853 28.1181 8.58182 24 8.58182Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1904_6356"
          x1="9.375"
          y1="-4.98176e-07"
          x2="51.1222"
          y2="27.6839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_1904_6356">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function ShipPackageCapsuleIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <rect
        width="48"
        height="48"
        rx="24"
        fill="url(#paint0_linear_1904_6267)"
      />
      <g clipPath="url(#clip0_1904_6267)">
        <path
          d="M24 40C19.726 40 15.7085 38.336 12.6865 35.3135C9.664 32.2915 8 28.274 8 24C8 19.726 9.664 15.7085 12.6865 12.6865C15.7085 9.664 19.726 8 24 8C28.274 8 32.2915 9.664 35.3135 12.6865C38.336 15.7085 40 19.726 40 24C40 28.274 38.336 32.2915 35.3135 35.3135C32.2915 38.336 28.274 40 24 40ZM24 8.58182C19.8819 8.58182 16.0099 10.1853 13.0979 13.0979C10.1853 16.0099 8.58182 19.8819 8.58182 24C8.58182 28.1181 10.1853 31.9901 13.0979 34.9021C16.0099 37.8147 19.8819 39.4182 24 39.4182C28.1181 39.4182 31.9901 37.8147 34.9021 34.9021C37.8147 31.9901 39.4182 28.1181 39.4182 24C39.4182 19.8819 37.8147 16.0099 34.9021 13.0979C31.9901 10.1853 28.1181 8.58182 24 8.58182Z"
          fill="white"
        />
        <path
          d="M27.6817 20.3491H20.3183V27.7126H27.6817V20.3491Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1904_4318"
          x1="9.375"
          y1="-4.98176e-07"
          x2="51.1222"
          y2="27.6839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_1904_4318">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function ShipPackagesCapsuleIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <rect
        width="48"
        height="48"
        rx="24"
        fill="url(#paint0_linear_1904_6267)"
      />
      <g clipPath="url(#clip0_1904_6267)">
        <path
          d="M24 40.0419C19.7149 40.0419 15.6864 38.3733 12.6569 35.3431C9.62677 32.3136 7.95811 28.2851 7.95811 24C7.95811 19.7149 9.62677 15.6864 12.6569 12.6569C15.6864 9.62678 19.7149 7.95813 24 7.95813C28.2851 7.95813 32.3136 9.62678 35.3431 12.6569C38.3732 15.687 40.0419 19.7155 40.0419 24C40.0419 28.2845 38.3732 32.3136 35.3431 35.3431C32.3136 38.3733 28.2851 40.0419 24 40.0419ZM24 8.53995C19.8703 8.53995 15.9878 10.1481 13.0682 13.0682C10.1481 15.9878 8.53993 19.8703 8.53993 24C8.53993 28.1298 10.1481 32.0122 13.0682 34.9318C15.9884 37.8519 19.8708 39.4601 24 39.4601C28.1298 39.4601 32.0122 37.8519 34.9318 34.9318C37.8519 32.0117 39.4601 28.1292 39.4601 24C39.4601 19.8709 37.8519 15.9878 34.9318 13.0682C32.0122 10.1481 28.1298 8.53995 24 8.53995Z"
          fill="white"
        />
        <path
          d="M21.6308 17.8031H17.8182V21.6158H21.6308V17.8031Z"
          fill="white"
        />
        <path
          d="M21.6308 26.3843H17.8182V30.1969H21.6308V26.3843Z"
          fill="white"
        />
        <path
          d="M30.1818 26.3843H26.3692V30.1969H30.1818V26.3843Z"
          fill="white"
        />
        <path
          d="M30.1871 17.8031H26.3634V21.6158H30.1871V17.8031Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1904_6267"
          x1="9.375"
          y1="-4.98176e-07"
          x2="51.1222"
          y2="27.6839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_1904_6267">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function RerouteCapsuleIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <rect
        width="48"
        height="48"
        rx="24"
        fill="url(#paint0_linear_1904_6300)"
      />
      <g clipPath="url(#clip0_1904_6300)">
        <path
          d="M24 40C19.726 40 15.7085 38.336 12.6865 35.3135C9.664 32.2915 8 28.274 8 24C8 19.726 9.664 15.7085 12.6865 12.6865C15.7085 9.664 19.726 8 24 8C28.274 8 32.2915 9.664 35.3135 12.6865C38.336 15.7085 40 19.726 40 24C40 28.274 38.336 32.2915 35.3135 35.3135C32.2915 38.336 28.274 40 24 40ZM24 8.58182C19.8819 8.58182 16.0099 10.1853 13.0979 13.0979C10.1853 16.0099 8.58182 19.8819 8.58182 24C8.58182 28.1181 10.1853 31.9901 13.0979 34.9021C16.0099 37.8147 19.8819 39.4182 24 39.4182C28.1181 39.4182 31.9901 37.8147 34.9021 34.9021C37.8147 31.9901 39.4182 28.1181 39.4182 24C39.4182 19.8819 37.8147 16.0099 34.9021 13.0979C31.9901 10.1853 28.1181 8.58182 24 8.58182Z"
          fill="white"
        />
        <path
          d="M26 20L33 25L26 30V19ZM18 24H29V26H18V24Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1904_6300"
          x1="9.375"
          y1="-4.98176e-07"
          x2="51.1222"
          y2="27.6839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_1904_6300">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function RedeemCapsuleIcon(props: IconProps) {
    return (
      <svg viewBox="0 0 48 48" {...props}>
      <rect
        width="48"
        height="48"
        rx="24"
        fill="url(#paint0_linear_1904_6300)"
      />
      <g clipPath="url(#clip0_1904_6300)">
        <path
          d="M24 40C19.726 40 15.7085 38.336 12.6865 35.3135C9.664 32.2915 8 28.274 8 24C8 19.726 9.664 15.7085 12.6865 12.6865C15.7085 9.664 19.726 8 24 8C28.274 8 32.2915 9.664 35.3135 12.6865C38.336 15.7085 40 19.726 40 24C40 28.274 38.336 32.2915 35.3135 35.3135C32.2915 38.336 28.274 40 24 40ZM24 8.58182C19.8819 8.58182 16.0099 10.1853 13.0979 13.0979C10.1853 16.0099 8.58182 19.8819 8.58182 24C8.58182 28.1181 10.1853 31.9901 13.0979 34.9021C16.0099 37.8147 19.8819 39.4182 24 39.4182C28.1181 39.4182 31.9901 37.8147 34.9021 34.9021C37.8147 31.9901 39.4182 28.1181 39.4182 24C39.4182 19.8819 37.8147 16.0099 34.9021 13.0979C31.9901 10.1853 28.1181 8.58182 24 8.58182Z"
          fill="white"
        />
        <path
          d="M19.2 24.5L22.8 28L30.5 20"
          fill="#616161"
          stroke="#616161"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 24L23 27L30 20"
          fill="none"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1904_6300"
          x1="9.375"
          y1="-4.98176e-07"
          x2="51.1222"
          y2="27.6839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_1904_6300">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
    )
  }
  

export function CancelCapsuleIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <rect
        width="48"
        height="48"
        rx="24"
        fill="url(#paint0_linear_1904_6300)"
      />
      <g clipPath="url(#clip0_1904_6300)">
        <path
          d="M24 40C19.726 40 15.7085 38.336 12.6865 35.3135C9.664 32.2915 8 28.274 8 24C8 19.726 9.664 15.7085 12.6865 12.6865C15.7085 9.664 19.726 8 24 8C28.274 8 32.2915 9.664 35.3135 12.6865C38.336 15.7085 40 19.726 40 24C40 28.274 38.336 32.2915 35.3135 35.3135C32.2915 38.336 28.274 40 24 40ZM24 8.58182C19.8819 8.58182 16.0099 10.1853 13.0979 13.0979C10.1853 16.0099 8.58182 19.8819 8.58182 24C8.58182 28.1181 10.1853 31.9901 13.0979 34.9021C16.0099 37.8147 19.8819 39.4182 24 39.4182C28.1181 39.4182 31.9901 37.8147 34.9021 34.9021C37.8147 31.9901 39.4182 28.1181 39.4182 24C39.4182 19.8819 37.8147 16.0099 34.9021 13.0979C31.9901 10.1853 28.1181 8.58182 24 8.58182Z"
          fill="white"
        />
        <path
          d="M27.6817 20.3491H20.3183V27.7126H27.6817V20.3491Z"
          stroke="white"
          fill="url(#clip0_1904_6300)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1904_6300"
          x1="9.375"
          y1="-4.98176e-07"
          x2="51.1222"
          y2="27.6839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_1904_6300">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function PortfolioCapsuleIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <rect
        width="48"
        height="48"
        rx="24"
        fill="url(#paint0_linear_1904_6300)"
      />
      <g clipPath="url(#clip0_1904_6300)">
        <path
          d="M24 40C19.726 40 15.7085 38.336 12.6865 35.3135C9.664 32.2915 8 28.274 8 24C8 19.726 9.664 15.7085 12.6865 12.6865C15.7085 9.664 19.726 8 24 8C28.274 8 32.2915 9.664 35.3135 12.6865C38.336 15.7085 40 19.726 40 24C40 28.274 38.336 32.2915 35.3135 35.3135C32.2915 38.336 28.274 40 24 40ZM24 8.58182C19.8819 8.58182 16.0099 10.1853 13.0979 13.0979C10.1853 16.0099 8.58182 19.8819 8.58182 24C8.58182 28.1181 10.1853 31.9901 13.0979 34.9021C16.0099 37.8147 19.8819 39.4182 24 39.4182C28.1181 39.4182 31.9901 37.8147 34.9021 34.9021C37.8141 31.9895 39.4182 28.1181 39.4182 24C39.4182 19.8819 37.8147 16.0099 34.9021 13.0979C31.9895 10.1859 28.1181 8.58182 24 8.58182Z"
          fill="white"
        />
        <path
          d="M19.7364 21.9129C18.5274 21.9129 17.5441 20.9296 17.5441 19.7206C17.5441 18.5116 18.5274 17.5283 19.7364 17.5283C20.9455 17.5283 21.9287 18.5116 21.9287 19.7206C21.9287 20.9296 20.9449 21.9129 19.7364 21.9129ZM19.7364 18.1101C18.8486 18.1101 18.126 18.8328 18.126 19.7206C18.126 20.6085 18.8486 21.3311 19.7364 21.3311C20.6243 21.3311 21.3469 20.6085 21.3469 19.7206C21.3469 18.8328 20.6243 18.1101 19.7364 18.1101Z"
          fill="white"
        />
        <path
          d="M19.7364 30.4715C18.5274 30.4715 17.5441 29.4882 17.5441 28.2792C17.5441 27.0702 18.5274 26.0869 19.7364 26.0869C20.9455 26.0869 21.9287 27.0702 21.9287 28.2792C21.9287 29.4882 20.9449 30.4715 19.7364 30.4715ZM19.7364 26.6687C18.8486 26.6687 18.126 27.3914 18.126 28.2792C18.126 29.1671 18.8486 29.8897 19.7364 29.8897C20.6243 29.8897 21.3469 29.1671 21.3469 28.2792C21.3469 27.3914 20.6243 26.6687 19.7364 26.6687Z"
          fill="white"
        />
        <path
          d="M28.2636 30.4715C27.0545 30.4715 26.0713 29.4882 26.0713 28.2792C26.0713 27.0702 27.0545 26.0869 28.2636 26.0869C29.4726 26.0869 30.4559 27.0702 30.4559 28.2792C30.4559 29.4882 29.4726 30.4715 28.2636 30.4715ZM28.2636 26.6687C27.3757 26.6687 26.6531 27.3914 26.6531 28.2792C26.6531 29.1671 27.3757 29.8897 28.2636 29.8897C29.1514 29.8897 29.874 29.1671 29.874 28.2792C29.874 27.3914 29.152 26.6687 28.2636 26.6687Z"
          fill="white"
        />
        <path
          d="M28.2694 21.9129H28.2583C27.0493 21.9129 26.066 20.9296 26.066 19.7206C26.066 18.5116 27.0493 17.5283 28.2583 17.5283H28.2694C29.4784 17.5283 30.4617 18.5116 30.4617 19.7206C30.4617 20.9296 29.4778 21.9129 28.2694 21.9129ZM28.2583 18.1101C27.3705 18.1101 26.6479 18.8328 26.6479 19.7206C26.6479 20.6085 27.3705 21.3311 28.2583 21.3311H28.2694C29.1572 21.3311 29.8799 20.6085 29.8799 19.7206C29.8799 18.8328 29.1572 18.1101 28.2694 18.1101H28.2583Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1904_6300"
          x1="9.375"
          y1="-4.98176e-07"
          x2="51.1222"
          y2="27.6839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_1904_6300">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function GitHubIcon(props: IconProps) {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.475 2 2 6.588 2 12.253c0 4.537 2.862 8.369 6.838 9.727.5.09.687-.218.687-.487 0-.243-.013-1.05-.013-1.91C7 20.059 6.35 18.957 6.15 18.38c-.113-.295-.6-1.205-1.025-1.448-.35-.192-.85-.667-.013-.68.788-.012 1.35.744 1.538 1.051.9 1.551 2.338 1.116 2.912.846.088-.666.35-1.115.638-1.371-2.225-.256-4.55-1.14-4.55-5.062 0-1.115.387-2.038 1.025-2.756-.1-.256-.45-1.307.1-2.717 0 0 .837-.269 2.75 1.051.8-.23 1.65-.346 2.5-.346.85 0 1.7.115 2.5.346 1.912-1.333 2.75-1.05 2.75-1.05.55 1.409.2 2.46.1 2.716.637.718 1.025 1.628 1.025 2.756 0 3.934-2.337 4.806-4.562 5.062.362.32.675.936.675 1.897 0 1.371-.013 2.473-.013 2.82 0 .268.188.589.688.486a10.039 10.039 0 0 0 4.932-3.74A10.447 10.447 0 0 0 22 12.253C22 6.588 17.525 2 12 2Z"
      />
    </svg>
  )
}

export function EthereumIcon(props: IconProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1080 1080"
      {...props}
    >
      <path
        className="opacity-40"
        fill="#F2F2F2"
        d="M539.96,26.4C256.84,26.4,26.4,256.76,26.4,540s230.44,513.6,513.56,513.6c283.21,0,513.64-230.36,513.64-513.6
      S823.16,26.4,539.96,26.4z"
      />
      <g className="opacity-80">
        <polygon
          fill="#D1D1D1"
          points="539.97,866.77 539.97,702.57 339.33,584.04 	"
        />
        <polygon
          fill="#D1D1D1"
          points="339.33,545.99 539.97,664.58 539.97,454.79 	"
        />
        <polygon
          fill="#B2B2B2"
          points="539.91,702.72 539.91,866.91 539.91,866.91 539.91,866.92 740.67,584.19 	"
        />
        <polygon
          fill="#F2F2F2"
          points="339.33,546 339.34,546 539.97,454.8 539.97,454.8 539.97,213.09 	"
        />
        <polygon
          fill="#B2B2B2"
          points="540.03,454.79 540.03,664.58 740.67,545.98 	"
        />
        <polygon
          fill="#D6D6D6"
          points="740.67,545.99 740.67,545.99 540.03,454.79 540.03,454.79 540.03,213.08 	"
        />
      </g>
    </svg>
  )
}

export function USDCIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="86977684-12db-4850-8f30-233a7c267d11"
      viewBox="0 0 2000 2000"
      {...props}
    >
      <path
        d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z"
        fill="#2775ca"
      />
      <path
        d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z"
        fill="#fff"
      />
      <path
        d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z"
        fill="#fff"
      />
    </svg>
  )
}

export function BlueEthereumIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 25 25" {...props}>
      <g clipPath="url(#clip0_1696_8348)">
        <path
          d="M12.5 24.3418C19.1274 24.3418 24.5 18.9692 24.5 12.3418C24.5 5.71438 19.1274 0.341797 12.5 0.341797C5.87258 0.341797 0.5 5.71438 0.5 12.3418C0.5 18.9692 5.87258 24.3418 12.5 24.3418Z"
          fill="#627EEA"
        />
        <path
          d="M12.8727 3.3418V9.9943L18.4954 12.5068L12.8727 3.3418Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path
          d="M12.8735 3.3418L7.25 12.5068L12.8735 9.9943V3.3418Z"
          fill="white"
        />
        <path
          d="M12.8727 16.8177V21.338L18.4992 13.5537L12.8727 16.8177Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path
          d="M12.8735 21.338V16.817L7.25 13.5537L12.8735 21.338Z"
          fill="white"
        />
        <path
          d="M12.8727 15.7718L18.4954 12.5071L12.8727 9.99609V15.7718Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M7.25 12.5071L12.8735 15.7718V9.99609L7.25 12.5071Z"
          fill="white"
          fillOpacity="0.602"
        />
      </g>
      <defs>
        <clipPath id="clip0_1696_8348">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.341797)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function CapsuleIcon(props: IconProps) {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 1080 1080" {...props}>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M635.89,576.92c23.73,56.7,45.26,80.07,92.67,96.11c91.48,30.94,224.98-46.12,197.94-152.65
            c-0.31-1.21-24.17,5.38-26.66,6.24c-11.72,4.06-21.34,11.21-28.56,21.28c-6.25,8.73-9.6,18.95-15.8,27.75
            c-22.08,31.35-64.79,46.87-101.5,34.19c-34.14-11.8-48.06-39.93-60.09-72.14c-12.73-34.1-23.25-74.74-28.56-110.56
            c-8.32-56.12,21.63-104.45,81.74-104.45c2.23,0,4.46,0.08,6.69,0.25c17.39,1.33,34.18,8.06,47.18,19.78
            c6.32,5.69,10.76,13.14,19.47,15.42c7.78,2.03,16.03-0.03,23.52-2.28c15.21-4.56,20.37-5.74,34.2-10.19
            c-2.88-13.68-10.42-26.93-19.13-37.71c-23.12-28.61-59.83-44.65-95.82-48.44c-60.41-6.36-120.24,23.37-149.27,77.21
            c-24.11,44.72-18.95,97.39-5.87,144.4c1.87,6.72,9.08,31.12,9.08,31.12c-77.9,31.35-157.66,52.94-238.02,70.71
            c-85.6,18.92-167.93,33.28-253.83,43.63c3.82,21.1,11.34,42.97,19.59,61.7c20.64-2.53,52.85-7.93,73.31-11.08
            C336.19,659.09,498.67,632.4,635.89,576.92z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M835.69,757.25c-35.26,8.87-146.19,21.28-213.47-47.51c-6.48-6.63-14.26-15.74-21.77-27.89
            c-37,11.99-75.96,23.39-116.82,33.84c-107.36,27.46-207.81,43.27-298.31,51.78c74.91,115.83,205.11,192.71,352.99,192.71
            c167.11,0,311.6-98,379.32-239.51c-15.29,10.38-31.69,19.17-48.89,25.96C857.97,750.88,846.92,754.42,835.69,757.25z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M539.96,5C245.04,5,5,244.96,5,540s240.04,535,534.96,535C834.96,1075,1075,835.04,1075,540
              S834.96,5,539.96,5z M538.31,1053.6c-283.12,0-513.56-230.36-513.56-513.6S255.18,26.4,538.31,26.4
              c283.21,0,513.64,230.36,513.64,513.6S821.51,1053.6,538.31,1053.6z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
