import Drawer from './Drawer'
import Image from 'next/image'

import ribbonComplete from '@/images/roadmap/done.svg'
import ribbonInProgress from '@/images/roadmap/in-progress.svg'
import { Box } from './Box'
import { useState } from 'react'
import { Button } from './Button'
import { DRUK_WIDE_VARIABLE, PLUS_JAKARTA_SANS_VARIABLE } from '@/pages/_app'

type Item = {
  title: string
  description: string
  status: 'in-progress' | 'done' | 'on-roadmap'
}

const items: Item[] = [
  {
    title: 'Iron Capsules',
    description:
      'Iron Capsules allow users to add or remove tokens without burning the underlying Capsule NFT.',
    status: 'in-progress',
  },
  {
    title: 'Alternative Chain Support',
    description:
      'By expanding onto more chains, we look forward to introducing the Capsule Protocol to more users and providing inspiration for new projects.',
    status: 'in-progress',
  },
  {
    title: 'Capsule Smart Wallet',
    description:
      'Integrating account abstraction (ERC-4337) to sponsor user payments and simplify user experience when interacting with the Capsule ecosystem.',
    status: 'on-roadmap',
  },
  {
    title: 'Lightning Bridge',
    description:
      'An advanced solution for seamless cross-chain transaction; up to 100x faster and 10x cheaper than traditional bridging solutions while staying just as secure.',
    status: 'on-roadmap',
  },
  {
    title: 'App Update',
    description:
      'The Capsule beta app is evolving to feature intuitive UI/UX for all levels of experienced users.',
    status: 'done',
  },
  {
    title: 'ERC-1155 Integration',
    description:
      'The integration of ERC-1155 tokens into the Capsule Protocol allows users to embed ERC-1155 tokens into Capsule NFTs — providing greater functionality and innovation, particularly in gaming.',
    status: 'done',
  },
  {
    title: 'NFT Launch',
    description:
      'With a unique NFT launch (Dollar Store Kids), we showcased the composable nature of Capsule NFTs and highlighted the ease of using the Capsule Protocol in your own projects. Code here.',
    status: 'done',
  },
  {
    title: 'Add-on Contracts',
    description:
      'Through collaboration with other developers, we will continue growing the Capsule ecosystem — creating additional contracts and integrations such as Loot Boxes, elastic supply collections, token-gated collections, and more.',
    status: 'done',
  },
]

function TrackSegment({ item, index }: { item: Item; index: number }) {
  return (
    <>
      {index > 0 && (
        <span
          className="absolute hidden h-1/2 w-0.5 bg-gray-200 sm:block"
          aria-hidden="true"
        />
      )}
      {index < items.length - 1 && (
        <span
          className="absolute top-1/2 hidden h-3/4 w-0.5 bg-gray-200 sm:block"
          aria-hidden="true"
        />
      )}
      <span
        className={`absolute -left-1.5 top-1/2 hidden sm:block ${
          item.status !== 'on-roadmap'
            ? 'bg-white'
            : 'border border-2 border-white bg-zinc-800'
        } h-3.5 w-3.5 rounded-full`}
      ></span>
    </>
  )
}

function RoadMapContent() {
  return (
    <ul role="list" className={`${DRUK_WIDE_VARIABLE} mb-8 space-y-6 pt-4`}>
      {items.map((item, index) => (
        <li className="relative" key={index}>
          <TrackSegment item={item} index={index} />
          <Box className="bg-capsule-gradient min-h-[12rem] sm:ml-6">
            <Box.Title
              className={`pt-4 pl-4 lg:text-3xl ${
                item.status === 'done' && 'text-zinc-400 opacity-80'
              }`}
            >
              {item.title}
            </Box.Title>
            <Box.Description
              className={`${PLUS_JAKARTA_SANS_VARIABLE} max-w-xl pl-4 pr-4 pb-4 font-base ${
                item.status === 'done'
                  ? 'text-zinc-400 opacity-80'
                  : 'text-zinc-300'
              }`}
            >
              {item.description}
            </Box.Description>
            {item.status !== 'on-roadmap' && (
              <Image
                className="absolute right-0 top-0 opacity-50 sm:opacity-100"
                src={item.status === 'done' ? ribbonComplete : ribbonInProgress}
                alt=""
                width={128}
              />
            )}
          </Box>
        </li>
      ))}
    </ul>
  )
}

export function Roadmap({ className }: { className?: string }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Drawer
        title="Roadmap"
        drawerContent={<RoadMapContent />}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Button className={className} onClick={() => setOpen((prev) => !prev)}>
        Roadmap
      </Button>
    </>
  )
}
